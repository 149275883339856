import * as React from 'react'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import { Link as RouterLink, GatsbyLinkProps, navigate } from 'gatsby'
import Link from '@material-ui/core/Link'

import { Layout } from '../components/layout'

import SEO from '../components/seo'

const LinkComponent = React.forwardRef<
  HTMLAnchorElement,
  Omit<GatsbyLinkProps<{}>, 'ref'>
>((props, ref) => (
  // @ts-ignore
  <RouterLink innerRef={ref} {...props} />
))

export default ({ pageContext }) => {
  const breadcrumb =
    pageContext.locale === 'fa' ? (
      <Breadcrumbs aria-label="breadcrumb" dir={pageContext.dir}>
        <Link component={LinkComponent} color="inherit" to="/fa">
          خانه
        </Link>
        <Typography color="textPrimary">خدمات</Typography>
      </Breadcrumbs>
    ) : (
      <Breadcrumbs aria-label="breadcrumb" dir={pageContext.dir}>
        <Link component={LinkComponent} color="inherit" to="/en">
          Home
        </Link>
        <Typography color="textPrimary">Services</Typography>
      </Breadcrumbs>
    )
  return (
    <Layout>
      <SEO title="Dental Services" />
      <Container maxWidth="md">
        <Box my={3}>{breadcrumb}</Box>
        <Box my={3} dir={pageContext.dir}>
          <Typography>{pageContext.body}</Typography>
        </Box>
      </Container>
    </Layout>
  )
}
